import React, { Component } from 'react'
import { Card, Col, Container } from 'react-bootstrap'
import { withTranslation } from 'react-i18next';
import { IoNotifications } from "react-icons/io5";
import { FaUsersGear } from "react-icons/fa6";
import { RiUserAddFill } from "react-icons/ri";
import { GetListUsers } from '../../components/Functions/GetUsersInfo';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetMachine_lastStatus } from '../../components/Functions/GetMachine';


export class Home extends Component {
    state = {
        totalUsers: 0,
        usersByLevelAuth: {
            "-1": 0,
            "0": 0,
            "1": 0,
            "2": 0
        },
        machinesData: {
            totalMachinesWithCP: 0,
            machinesByDepartment: {},
            totalMachinesWithoutCP: 0,
        }
    };

    componentDidMount() {
        this.fetchUsers();
        this.fetchMachines();
    }

    fetchUsers = async () => {
        try {
            const users = await GetListUsers();

            this.processUsers(users);
        } catch (error) {
            console.error("Errore nel recupero degli utenti:", error);
        }
    };

    fetchMachines = async () => {
        try {
            const macchine = await GetMachine_lastStatus();
            this.processMachines(macchine.STATI);
        } catch (error) {
            console.error("Errore nel recupero delle macchine:", error);
        }
    }

    processUsers = (users) => {
        let usersByLevelAuth = { "-1": 0, "0": 0, "1": 0, "2": 0 };
        //console.log(users)
        users.forEach(user => {
            try {
                const levelAuthAttribute = user.Attributes.find(attr => attr.Name === 'custom:configSys').Value;
                const levelAuth = JSON.parse(levelAuthAttribute).FMM.factories.find(factory => factory.hasOwnProperty('Malo')).Malo.levelAuth;

                if (levelAuth !== undefined && usersByLevelAuth.hasOwnProperty(levelAuth.toString())) {
                    usersByLevelAuth[levelAuth.toString()] += 1;
                }
            } catch (error) {
                toast.error('Errore utente', user.Username, error);
            }

        });

        this.setState({
            totalUsers: users.length,
            usersByLevelAuth: usersByLevelAuth
        });
    };

    processMachines = (machines) => {
        let totalMachinesWithCP = 0;
        let machinesByDepartment = {};
        let totalMachinesWithoutCP = 0;

        machines.forEach(machine => {
            if (machine.CP !== "null") { // Controlla se CP è diverso da null (nota: "null" è una stringa qui)
                totalMachinesWithCP += 1;

                // Aggiorna il conteggio per ogni reparto
                machinesByDepartment[machine.IDR] = (machinesByDepartment[machine.IDR] || 0) + 1;
            } else {
                totalMachinesWithoutCP += 1;
            }
        });

        this.setState({
            machinesData: {
                totalMachinesWithCP,
                machinesByDepartment,
                totalMachinesWithoutCP,
            }
        });
    };

    render() {

        const { totalUsers, usersByLevelAuth, machinesData } = this.state;
        //const totalLevels = Object.keys(usersByLevelAuth).length;
        console.log(this.state);

        const levelColors = {
            "-1": "#fe42a7",
            "0": "#1c5f8c",
            "1": "#ff9600",
            "2": "#49e80a",
            "TOT_machines": "rgb(0, 128, 0)",
            "Macchinari_dismessi": "#c73e3e",
            "TaglioAusiliari": "#ffb700",
            "TaglioPelle": "#3e98c7"
        };
        const levelLabels = {
            "-1": "Superadmin",
            "0": "Capo Stabilimento",
            "1": "Capo Reparto",
            "2": "Postazione",
            "TOT_machines": "Tot macchinari: ",
            "Macchinari_dismessi": "Macchinari dismessi",
            "TaglioAusiliari": "Taglio Ausiliari",
            "TaglioPelle": "Taglio Pelle"

        };
        return (


            <Container >

                <Card.Title style={{ textAlign: 'center', fontSize: 'x-large', fontWeight: 'bold', marginTop: '2%', marginBottom: '5%' }}>
                    Portale di gestione piattaforma Factory machine Monitorning
                </Card.Title>
                <Card.Body>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Link to='/Users/newUser' style={{ textDecoration: 'none' }}>
                            <Card>
                                <Card.Body style={{ display: 'flex' }}>
                                    <RiUserAddFill size={22} />
                                    <span style={{ marginLeft: '10px' }}> Crea nuovo utente</span>
                                </Card.Body>
                            </Card>
                        </Link>
                        <Link to='/Users' style={{ textDecoration: 'none' }}>
                            <Card>
                                <Card.Body style={{ display: 'flex' }}>
                                    <FaUsersGear size={22} />
                                    <span style={{ marginLeft: '10px' }}>Visualizza lista utenti</span>

                                </Card.Body>
                            </Card>
                        </Link>
                        <Link to='/Settings/Notifications' style={{ textDecoration: 'none' }}>
                            <Card>
                                <Card.Body style={{ display: 'flex' }}>
                                    <IoNotifications size={22} />
                                    <span style={{ marginLeft: '10px' }}>Impostazioni sistema delle notifiche</span>
                                </Card.Body>
                            </Card>
                        </Link>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', marginTop: '3%' }}>
                        {/* Cerchio per il totale degli utenti */}


                        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', marginTop: '3%' }}>
                            <Card style={{ width: '13%', textAlign: 'center' }}>
                                <Card.Body>
                                    <CircularProgressbar
                                        value={100}
                                        text={`Totali: ${totalUsers}`}
                                        styles={buildStyles({
                                            pathColor: `rgb(0, 128, 0)`,
                                            textColor: `rgb(0, 128, 0)`,
                                            trailColor: '#d6d6d6',
                                            textSize: '16px'
                                        })}
                                    />
                                </Card.Body>
                            </Card>
                            {Object.entries(usersByLevelAuth).map(([level, count]) => (
                                <Card key={level} style={{ width: '13%', textAlign: 'center', padding: '20px' }}>
                                    <div style={{ width: '100%', position: 'relative' }}>
                                        <CircularProgressbar
                                            value={totalUsers > 0 ? (count / totalUsers) * 100 : 0}
                                            styles={{
                                                root: {},
                                                path: {
                                                    stroke: levelColors[level],
                                                },
                                                trail: {
                                                    stroke: '#d6d6d6',
                                                },
                                                text: {
                                                    fill: levelColors[level],
                                                    // textAlign: 'center' non è un'opzione supportata, ma puoi centrare il testo con CSS esterni
                                                },
                                            }}
                                        />
                                        <div style={{ position: 'absolute', width: '100%', top: '50%', left: '0', transform: 'translateY(-50%)', textAlign: 'center' }}>
                                            <div>{levelLabels[level]}</div>
                                            <div>{count}</div>
                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </div>




                    {/*</Card.Body>*/}


                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', marginTop: '3%' }}>
                        {/* Cerchio per il totale degli utenti */}


                        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', marginTop: '3%' }}>

                            <Card key={'TOT_machines'} style={{ width: '13%', textAlign: 'center', padding: '20px' }}>
                                <div style={{ width: '100%', position: 'relative' }}>
                                    <CircularProgressbar
                                        value={100}
                                        styles={{
                                            root: {},
                                            path: {
                                                stroke: levelColors['TOT_machines'],
                                            },
                                            trail: {
                                                stroke: '#d6d6d6',
                                            },
                                            text: {
                                                fill: levelColors['TOT_machines'],
                                                // textAlign: 'center' non è un'opzione supportata, ma puoi centrare il testo con CSS esterni
                                            },
                                        }}
                                    />
                                    <div style={{ position: 'absolute', width: '80%', top: '50%', left: '10%', transform: 'translateY(-50%)', textAlign: 'center' }}>
                                        <div>{levelLabels['TOT_machines']}</div>
                                        <div>{machinesData.totalMachinesWithCP}</div>
                                    </div>
                                </div>
                            </Card>
                            {Object.entries(machinesData.machinesByDepartment).map(([department, count]) => (
                                <Card key={department} style={{ width: '13%', textAlign: 'center', padding: '20px' }}>
                                    <div style={{ width: '100%', position: 'relative' }}>
                                        <CircularProgressbar
                                            value={machinesData.totalMachinesWithCP > 0 ? (count / machinesData.totalMachinesWithCP) * 100 : 0}
                                            styles={{
                                                root: {},
                                                path: {
                                                    stroke: levelColors[department],
                                                },
                                                trail: {
                                                    stroke: '#d6d6d6',
                                                },
                                                text: {
                                                    fill: levelColors[department],
                                                    // textAlign: 'center' non è un'opzione supportata, ma puoi centrare il testo con CSS esterni
                                                },
                                            }}
                                        />
                                        <div style={{ position: 'absolute', width: '80%', top: '50%', left: '10%', transform: 'translateY(-50%)', textAlign: 'center' }}>
                                            <div>{levelLabels[department]}</div>
                                            <div>{count}</div>
                                        </div>
                                    </div>
                                </Card>
                            ))}

                            <Card key={'Macchinari_dismessi'} style={{ width: '13%', textAlign: 'center', padding: '20px' }}>
                                <div style={{ width: '100%', position: 'relative' }}>
                                    <CircularProgressbar
                                        value={100}
                                        styles={{
                                            root: {},
                                            path: {
                                                stroke: levelColors['Macchinari_dismessi'],
                                            },
                                            trail: {
                                                stroke: '#d6d6d6',
                                            },
                                            text: {
                                                fill: levelColors['Macchinari_dismessi'],
                                                // textAlign: 'center' non è un'opzione supportata, ma puoi centrare il testo con CSS esterni
                                            },
                                        }}
                                    />
                                    <div style={{ position: 'absolute', width: '80%', top: '50%', left: '10%', transform: 'translateY(-50%)', textAlign: 'center' }}>
                                        <div>{levelLabels['Macchinari_dismessi']}</div>
                                        <div>{machinesData.totalMachinesWithoutCP}</div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Card.Body>


            </Container>

        )
    }
}

export default withTranslation()(Home)