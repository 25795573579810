/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:4a16c0b3-460a-4166-bdc9-5fe9adee3cd3",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_lEds1ZMFd",
    "aws_user_pools_web_client_id": "30bvviq36bcqbk4lgeat73n11j",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bvfmm-ambiente-test062023121439-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
